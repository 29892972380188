import utils from '~/utils';

const { getFullDate } = utils;

export default [
  {
    width: 35,
    header: {
      type: 'text',
      caption: 'Requisites',
    },
    cell: {
      type: 'text',
      value: (e) => e.requisites,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Date created',
      sort: 'dateCreated',
    },
    cell: {
      type: 'text',
      value: (e) => getFullDate(e.dateCreated),
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Date deleted',
      sort: 'dateDeleted',
    },
    cell: {
      type: 'text',
      value: (e) => {
        if (e.isDeleted) {
          return getFullDate(e.dateDeleted);
        }
        return '–';
      },
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Service',
    },
    cell: {
      type: 'text',
      value: (e) => e.transactionMethodTitle,
    },
  },
];
