//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('common', [
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('userId', {
      removeUserAction: 'removeUser',
    }),

    async removeUser() {
      this.confirmAction({
        title: 'Are you sure you want to delete user?',
        callback: async () => {
          this.loader = true;
          try {
            const { id } = this.$route.params;
            await this.removeUserAction(id);
            await this.setSuccessNotification('User successfuly deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
        },
      });
    },
  },
};
