var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-verification-requests"},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('Verification requests'))+" ")]),_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.generalProgress || _vm.isSetStatusDisabled},on:{"click":_vm.resetVerification}},[_vm._v(" "+_vm._s(_vm.$t('Reset verification'))+" ")])],1),_c('div',{staticClass:"user-verification-requests__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"287px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{class:_vm.getVerificationStatusClass(row.verificationStatus)},[_vm._v(" "+_vm._s(_vm.getVerificationStatus(row.verificationStatus))+" ")]),(row.verificationAutoList.length)?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.showAutoVerificationDetails(row)}}},[_vm._v(" mdi-help-circle-outline ")]):_vm._e()],1)]}},{key:"comment",fn:function(ref){
var row = ref.row;
return [(row.comment)?_c('v-tooltip',{attrs:{"max-width":"600px","transition":"fade-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.getAdmin(row.adminId))+": ")]),_vm._v(" "+_vm._s(row.comment)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(row.comment)+" ")])]):_c('span',[_vm._v("–")])]}},{key:"documents",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._l((row.userDocumentList.filter(function (e) { return e.userFile; })),function(document){return _c('div',{key:document.userFile.id,staticClass:"primary--text user-verification-requests__files text-truncate",on:{"click":function($event){return _vm.showDocument(document.userFile.id)}}},[_vm._v(" "+_vm._s(document.userFile.name)+"."+_vm._s(document.userFile.extension)+" ")])}),_c('v-spacer'),_c('v-btn',{class:_vm.getVerificationStatusClass(row.verificationStatus),attrs:{"text":"","small":""},on:{"click":function($event){return _vm.showMore(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('Show more'))+" ")])],2)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('ImagePreview',{attrs:{"show":_vm.showPreview,"src":_vm.userFile},on:{"hide":_vm.hidePreview}}),_c('AutoVerificationDetailsModal',{attrs:{"data":_vm.autoVerificationDetailsModal.data},model:{value:(_vm.autoVerificationDetailsModal.show),callback:function ($$v) {_vm.$set(_vm.autoVerificationDetailsModal, "show", $$v)},expression:"autoVerificationDetailsModal.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }