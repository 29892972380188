//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import constant from '~/const';
import TableKeyValue from '~/components/ui/TableKeyValue.vue';

export default {
  components: {
    TableKeyValue,
  },

  data() {
    return {
      index: 0,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('userId', ['userDocuments', 'verificationInfo']),
    ...mapGetters('userId', ['filteredUserDocuments']),

    verificationInfoTable() {
      const {
        dateCreated,
        gender,
        firstName,
        lastName,
        middleName,
        birthday,
        country,
        city,
        address,
        postalCode,
        citizenship,
        passportNumber,
        passportAddress,
        passportIssedBy,
        passportIssuedDate,
        passportExpireDate,
      } = this.verificationInfo;
      return [
        { key: 'Date', value: dateCreated ? this.$utils.getDate(dateCreated) : '' },
        { key: 'Gender', value: this.$utils.getCaption(constant.user.GENDER_LIST, gender) },
        { key: 'First name', value: firstName },
        { key: 'Last name', value: lastName },
        { key: 'Middle name', value: middleName },
        { key: 'Birthday', value: birthday ? this.$utils.getDate(birthday) : '' },
        { key: 'Country', value: country },
        { key: 'City', value: city },
        { key: 'Address', value: address },
        { key: 'Postal code', value: postalCode },
        { key: 'Citizenship', value: citizenship },
        { key: 'Passport number', value: passportNumber },
        { key: 'Passport address', value: passportAddress },
        { key: 'Passport issued by', value: passportIssedBy },
        {
          key: 'Passport issued date',
          value: passportIssuedDate
            ? this.$utils.getDate(passportIssuedDate)
            : '',
        },
        {
          key: 'Passport expire date',
          value: passportExpireDate
            ? this.$utils.getDate(passportExpireDate)
            : '',
        },
      ];
    },
  },

  methods: {
    ...mapActions('addDocuments', {
      setAddDocumentsModal: 'setModal',
    }),
    getInfoTable(item) {
      const {
        firstName,
        lastName,
        middleName,
        birthday,
        country,
        city,
        address,
        postalCode,
      } = item;
      return [
        { key: 'First name:', value: firstName },
        { key: 'Last name:', value: lastName },
        { key: 'Middle name:', value: middleName },
        {
          key: 'Birth date:',
          value: birthday
            ? this.$utils.getDate(birthday)
            : '–',
        },
        { key: 'Country:', value: country },
        { key: 'City:', value: city },
        { key: 'Address:', value: address },
        { key: 'Postcode:', value: postalCode },
      ];
    },

    getDocumentTable(item) {
      const {
        documentNumber,
        documentAddress,
        documentIssuedBy,
        documentIssuedDate,
        documentExpireDate,
      } = item;
      return [
        { key: 'Number:', value: documentNumber },
        { key: 'Address:', value: documentAddress },
        { key: 'Issued by:', value: documentIssuedBy },
        {
          key: 'Issued date:',
          value: documentIssuedDate
            ? this.$utils.getDate(documentIssuedDate)
            : '–',
        },
        {
          key: 'Expire date:',
          value: documentExpireDate
            ? this.$utils.getDate(documentExpireDate)
            : '–',
        },
      ];
    },

    getDocumentCaption(id) {
      const { DOCUMENT_TYPE_LIST } = constant.user;
      const documentType = DOCUMENT_TYPE_LIST.find((e) => e.id === id);
      if (documentType) {
        return documentType.caption;
      }
      return '';
    },

    change() {
      this.setAddDocumentsModal({
        show: true,
        requestId: 0,
      });
    },
  },
};
