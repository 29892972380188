//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import User from '~/components/User.vue';

export default {

  components: { User },
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isNewDocument: { type: Boolean, required: true },
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    add() {
      this.model = false;
      this.$emit('add');
    },

    update() {
      this.model = false;
      this.$emit('update');
    },
  },
};
