//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';
import { getCaption } from '~/utils/common';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
  },

  data() {
    return {
      constant,
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    tableConfig() {
      return [
        {
          width: 10,
          header: { type: 'text', caption: 'Id' },
          cell: {
            type: 'text',
            value: (e) => e.id,
          },
        },
        {
          width: 10,
          header: { type: 'text', caption: 'Type' },
          cell: {
            type: 'text',
            value: (e) => getCaption(constant.settings.VERIFICATION_AUTO_TYPE_LIST, e.type),
          },
        },
        {
          width: 10,
          header: { type: 'text', caption: 'Status' },
          cell: {
            type: 'text',
            value: (e) => getCaption(constant.user.AUTO_VERIFICATION_STATUS_LIST, e.status),
          },
        },
        {
          width: 60,
          header: { type: 'text', caption: 'Details' },
          cell: {
            type: 'text',
            value: (e) => e.details,
          },
        },
        {
          width: 10,
          header: { type: 'text', caption: 'Active' },
          cell: {
            type: 'text',
            value: (e) => (e.active ? 'Yes' : 'No'),
          },
        },
      ];
    },
  },
};
