//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Pagination from '~/components/Pagination.vue';
import ImagePreview from '~/components/ImagePreview.vue';
import AutoVerificationDetailsModal from '~/views/UserId/UserVerificationRequests/modals/AutoVerificationDetails/AutoVerificationDetails.vue';

import tableConfig from './tableConfig';

export default {
  components: {
    Pagination,
    ImagePreview,
    AutoVerificationDetailsModal,
  },

  data() {
    return {
      loader: false,
      showPreview: false,
      autoVerificationDetailsModal: {
        show: false,
        data: [],
      },
      tableConfig,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader', 'generalProgress', 'status']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('login', ['getAdminById']),

    ...mapState('userVerificationRequests', ['page', 'pages', 'data', 'sort']),
    ...mapState('userId', ['userFile']),
    ...mapState('user', ['user']),

    lastRequestId() {
      const lastRequest = this.data[0];

      if (!lastRequest) return 0;
      return lastRequest.id;
    },

    isSetStatusDisabled() {
      const lastRequest = this.data[0];
      const {
        VERIFICATION_REQUEST,
        VERIFIED_VERIFICATION_REQUEST,
      } = constant.user.VERIFICATION_STATUS;

      if (!lastRequest) return true;
      if (
        ![
          VERIFICATION_REQUEST,
          VERIFIED_VERIFICATION_REQUEST,
        ].includes(lastRequest.verificationStatus)
      ) return true;
      return false;
    },
  },

  watch: {
    generalLoader(value) {
      if (!value) this.openVerification();
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setGeneralProgress',
      'confirmAction',
    ]),
    ...mapActions('userVerificationRequests', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),
    ...mapActions('addDocuments', {
      setAddDocumentsModal: 'setModal',
    }),
    ...mapActions('userId', ['getUserFile']),
    ...mapActions('addDocuments', ['manualVerificationResponse']),

    async loadData(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.loadDataAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    getVerificationStatus(id) {
      const { VERIFICATION_STATUS_LIST } = constant.user;
      const verificationStatus = VERIFICATION_STATUS_LIST.find((e) => e.id === id);
      if (verificationStatus) {
        return verificationStatus.caption;
      }
      return '';
    },

    getVerificationStatusClass(id) {
      const {
        // UNVERIFIED,
        // IN_PROCESS,
        // REQUIRES_DATA,
        VERIFIED,
        VERIFICATION_REQUEST,
        VERIFIED_VERIFICATION_REQUEST,
      } = constant.user.VERIFICATION_STATUS;
      switch (id) {
        case VERIFICATION_REQUEST:
        case VERIFIED_VERIFICATION_REQUEST:
          return 'warning--text';
        case VERIFIED:
          return 'success--text';
        default:
          return '';
      }
    },

    async showDocument(fileId) {
      this.setGeneralProgress(true);
      await this.getUserFile(fileId);
      this.setGeneralProgress(false);
      this.showPreview = true;
    },

    hidePreview() {
      this.showPreview = false;
    },

    showMore(requestId) {
      this.setAddDocumentsModal({
        show: true,
        requestId,
      });
    },

    getAdmin(id) {
      const admin = this.getAdminById(id);
      if (admin) {
        return admin.email;
      }
      return this.$t('Unknown admin');
    },

    async resetVerification() {
      const { lastRequestId } = this;
      const { language } = this.user;

      this.confirmAction({
        title: 'Are you sure?',
        hasPrompt: true,
        promptRequired: true,
        promptLabel: this.$t('confirmAction.Comment for user $language', { language: this.status.languageList[language].toUpperCase() }),
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          await this.manualVerificationResponse({
            verificationRequestId: lastRequestId,
            verificationResponseType: constant.user.VERIFICATION_RESPONSE_TYPE.RESET,
            comment: prompt,
            force: false,
          });
          this.setGeneralProgress(false);
          this.loadData({});
          this.setSuccessNotification('Verification reseted');
        },
      });
    },

    openVerification() {
      const { openVerification } = this.$route.query;
      const { data } = this;
      if (openVerification) {
        this.$router.replace({ query: {} });
        if (data?.length) {
          this.showMore(data[0].id);
        }
      }
    },

    showAutoVerificationDetails(row) {
      this.autoVerificationDetailsModal.data = row.verificationAutoList;
      this.autoVerificationDetailsModal.show = true;
    },
  },
};
