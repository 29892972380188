//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import IdentificationTable from '~/views/UserId/IdentificationTable.vue';
import BasicInfo from '~/views/UserId/BasicInfo.vue';
import UserVerificationRequests from '~/views/UserId/UserVerificationRequests/UserVerificationRequests.vue';
import AddDocuments from '~/views/UserId/UserVerificationRequests/modals/AddDocuments/AddDocuments.vue';
import DeleteUser from '~/views/UserId/DeleteUser.vue';
import UserRequisites from '~/views/UserId/UserRequisites/UserRequisites.vue';

export default {
  components: {
    IdentificationTable,
    BasicInfo,
    UserRequisites,
    UserVerificationRequests,
    AddDocuments,
    DeleteUser,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadUserDocuments(),
      this.loadUserRequisites(),
      this.loadUserVerificationRequests(),
      this.loadP2pArbitratorsList(),
      this.loadPersonalFees({ userId: this.user?.id }),
      this.loadTradersCurrencies({ limit: 1000 }),
      this.loadUserGoups(),
    ]);
    this.setGeneralLoader(false);
  },

  computed: {
    ...mapState('user', ['user']),
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification']),
    ...mapActions('userId', ['loadUserDocuments']),
    ...mapActions('userRequisites', {
      loadUserRequisites: 'loadData',
    }),
    ...mapActions('userVerificationRequests', {
      loadUserVerificationRequests: 'loadData',
    }),
    ...mapActions('p2pArbitratorsList', {
      loadP2pArbitratorsList: 'loadData',
    }),
    ...mapActions('tradersFees', {
      loadPersonalFees: 'loadPersonalFeeDataById',
    }),
    ...mapActions('tradersCurrencies', {
      loadTradersCurrencies: 'loadData',
    }),
    ...mapActions('usersGroups', {
      loadUserGoups: 'getGroups',
    }),
  },
};
