//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    header: { type: Object, default: null },
    data: { type: Array, default: () => [] },
    className: { type: String, default: '' },
  },
};
