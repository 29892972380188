//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    show: { type: Boolean, default: false },
    src: { type: String, default: '' },
  },

  methods: {
    hide() {
      this.$emit('hide');
    },

    openInTab() {
      const { src } = this;
      const tab = window.open();
      tab.document.body.innerHTML = `<img src="${src}"/>`;
    },
  },
};
