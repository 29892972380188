/* eslint-disable import/prefer-default-export */

export const basicInfoInfoTable = [
  'Username:',
  'Id:',
  'Reg date:',
  'Identification date:',
  'Identification:',
  'Country:',
  'Email:',
  'Fees:',
  'Previous owners:',
  'Mobile Number:',
  'Telegram Id:',
  'Tags:',
  'Contacts:',
  'MFA:',
  'UTM List:',
  'Telegram Bot:',
  'Last login:',
];
